
export default {
  props: {
    data: {
      default: ''
    },
  },
  methods: {
    // To add a fallback placeholder image if not rendereds from API
    setFallbackImageUrl(event) {
      event.target.src = require(`@/assets/img/placeholders/thumbnail-square.svg`)
    },
  }

}

