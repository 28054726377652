
  export default {
    props: {
      reviewId: {
        default: 0
      },
      reviewer: {
        type: String,
        default: ''
      },
      source: {
        type: String,
        default: ''
      },
      ratings: {
        type: Number,
        default: 0
      },
      review: {
        type: String,
        default: ''
      },
      reviewDate: {
        type: String,
        default: ''
      },
      showSource: {
        type: Boolean,
        default: false
      },
      isEditable: {
        type: Boolean,
        default: false
      },
      isVerified: {
        type: Boolean,
        default: false
      },
      showReview: {
        type: Boolean,
        default: false
      }

    },
  }

