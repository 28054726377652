
export default {
  props: {
    link: {
      type: String,
      required: true,
      default: '/'
    },
    text: {
      type: String,
      required: true,
      default: 'Text'
    },
    backgroundImage: {
      type: String,
      required: false,
      default:'https://storage.googleapis.com/goa-app-38c9a.appspot.com/2023-06-30T11%3A49%3A14.976Zparty.jpg?GoogleAccessId=firebase-adminsdk-h8jpw%40goa-app-38c9a.iam.gserviceaccount.com&Expires=16446997800&Signature=EwU3TQ1a%2F6QmVkn9MhO3i%2FBaeURdwuZx%2FY4fXvH%2BdSYmYt%2FDinbIfkogxWv3lDYgzpt%2BVzR0VUB3NkSJKcYtgoFOp7quKhzOk8agf30NEYPuXVPKss393FJ%2FdKSMv1SGMf9DZRsOCBn9OfE%2BHLDHgdAhYYu2uimWxG9BczP4%2FgO%2F%2FDygT%2Bpra50iTncEbZ%2B27C0My3IiUaa9mqF0zphjdgUohwXI%2BOAjzBQyaZeZ9gKTyY6b4QFRkdpElceE%2FVucHOPKE9y1vLFqbbLDB0lOdP%2B%2FNDF%2BMTRM3kaIWl9tggZZxv8XAbvAqzhsz%2F82YfECPOi3pGEFjnZLoCmY415V1A%3D%3D'
    },
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      };
    },
  },
};
