
export default {
  data(){
    return{
      recognition: {
          infinite: true,
          slidesToShow: 7,
          slidesToScroll: 1,
          speed: 400,
          dots: false,
          arrows: false,
          responsive: [
            {
            breakpoint: 1200,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },


        ]
        },
    }
  }
}
