
  export default {
    props: {
      packageName: {
        type: String,
        default: 'Package Name'
      },
      couponName: {
        type: String,
        default: 'Coupon Name'
      },
      couponAmt: {
        type: Number,
        default: '0'
      },
      minSpend: {
        type: Number,
        default: '0'
      },
      isActivity: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        copiedUrl: false
      };
    },
    methods: {
      copyLink(textToCopy) {
        if(navigator)
        navigator.clipboard.writeText(textToCopy);
        this.copiedUrl = true;
        this.$emit('couponCopied', this.copiedUrl)
        setTimeout(() => {
          this.copiedUrl = false;
        }, 3000)
      }
    }
  }
