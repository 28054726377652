
import moment from "moment";

export default {
  props: {
    link: {
      type: String,
      required: true,
      default: '/'
    },
    image: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.updateHeight();
    window.addEventListener('resize', this.updateHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateHeight);
  },
  methods: {
    // To add a fallback placeholder image if not rendered from API
    setFallbackImageUrl(event) {
      event.target.src = require('@/assets/img/placeholders/thumbnail-square.svg');
    },
    updateHeight() {
      const container = this.$refs.resizeContainer;
      if (container) {
        // Adjust container height to match its width for a 1:1 aspect ratio
        const width = container.offsetWidth;
        container.style.height = `${width}px`;
      }
    }
  }
}
