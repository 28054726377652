
import moment from "moment";
export default {
  destroyed() {
    document.body.classList.remove('modalOverflow');
    if (document.getElementById('locationClose'))
      document.getElementById('locationClose').click();
  },

  methods: {
    setInStorage(toStore, locDet = null) {
      let store = {
        permissionGranted: toStore,
        locationDets: locDet,
      }
      localStorage.setItem("loc", JSON.stringify(store));
    },
    requestLoc() {
      var self = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
            var coords = {
              lat: position.coords.latitude,
              long: position.coords.longitude
            }
            self.$emit("locationDetails", coords);
            self.setInStorage(true, coords)
          },
          function(error) {
            if (error.code == error.PERMISSION_DENIED)
              self.setInStorage(false)
          });
      }
    },

  }
}
