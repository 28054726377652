
  import moment from "moment";
  export default {
    props: {
      item: {
        type: Object,
        default: {}
      },
    },
    methods: {
      getGalleryImg(imageArr) {
        if (imageArr && imageArr.length > 0) {
          return imageArr[0]
        }
        return ''
      },
      // To add a fallback placeholder image if not rendereds from API
      setFallbackImageUrl(event) {
        event.target.src = require(`@/assets/img/placeholders/thumbnail-square.svg`)
      },
      checkStartAndEndDate(start,end){
        var startDate = moment(start).utc().format('DD-MM-YYYY');
        var endDate = moment(end).utc().format('DD-MM-YYYY');
        return moment(startDate ,"DD-MM-YYYY").isSame( moment(endDate ,"DD-MM-YYYY") );
      }

    }

  }
