
export default {
    props: {
        trendingImage: {
            type: String,
            default: 'https://picsum.photos/600/600.webp'
        },
        trendingCategory: {
            type: String,
            default: 'Category'
        },
        trendingPlace: {
            type: String,
            default: 'Place'
        },
        trendingName: {
            type: String,
            default: 'Trending Name'
        },
        trendingLink: {
            type: String,
            default: '#'
        },
        trendingBestSeller: {
            type: Boolean,
            default: false
        },
        trendingDate: {
            type: Object,
            default: () => ({ date: '', time: '', }),
        },
        trendingAmount: {
            type: String,
            default: '₹₹₹'
        }
    }
}
