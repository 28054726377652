
  //## VUE SLICK
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  export default {
    components: {
      VueSlickCarousel
    },
    data() {
      return {
         trendingSlick: {
        slidesToShow: 6,
        arrows: false,
        adaptiveHeight: true,
        focusOnSelect: true,
        swipe: true,
        mobileFirst: true,
        touchMove: true,
        waitForAnimate: true,
        focusOnChange: true,
        cssEase: 'ease',
        easing: 'linear',
        useCSS: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4.2,
              slidesToScroll: 1,
              infinite: false,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3.2,
              slidesToScroll: 1,
              infinite: false,
            }
          }, {
            breakpoint: 575,
            settings: {
              slidesToShow: 2.2,
              slidesToScroll: 1,
              infinite: false,
            }
          }, {
            breakpoint: 375,
            settings: {
              slidesToShow: 2.1,
              slidesToScroll: 1,
              infinite: false,
            }
          }

        ]
      }
      }
    },


  }

