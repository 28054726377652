
export default {
  props: {
    link: {
      type: String,
      required: true,
      default: '/'
    },
    image: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ""
    },
   
  },
  methods: {
    // To add a fallback placeholder image if not rendereds from API
    setFallbackImageUrl(event) {
      event.target.src = require(`@/assets/img/placeholders/thumbnail-square.svg`)
    }
  }

}

