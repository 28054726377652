import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5439e78e&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=5439e78e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5439e78e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainCategoryCard: require('/workspace/components/MainCategoryCard.vue').default,PopularCategoryCard: require('/workspace/components/PopularCategoryCard.vue').default,BannerSliderItem: require('/workspace/components/BannerSliderItem.vue').default,ShimmerEffect: require('/workspace/components/ShimmerEffect.vue').default,ActivityBlog: require('/workspace/components/ActivityBlog/ActivityBlog.vue').default,FilterCard: require('/workspace/components/FilterCard.vue').default,RentalCardV3: require('/workspace/components/RentalCardV3.vue').default,PremiumCoupon: require('/workspace/components/PremiumCoupon.vue').default,EventCardGeneral: require('/workspace/components/EventCardGeneral.vue').default,PlaceCardv2: require('/workspace/components/PlaceCardv2.vue').default,PlaceCard: require('/workspace/components/PlaceCard.vue').default})
