
  //## VUE SLICK
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  export default {
    components: {
      VueSlickCarousel
    },
    data() {
      return {
        reviewSlick: {
          dots: false,
          infinite: true,
          arrows: true,
          autoplay: true,
          speed: 400,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [{
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true,
                arrows: false
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: false
              }
            },
            {
              breakpoint: 450,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: false

              }
            }
          ]
        }
      }
    },
    methods: {
      showPrev() {
        this.$refs.carousel.prev()
      },
      showNext() {
        this.$refs.carousel.next()
      },
    },

  }

