
  //## VUE SLICK
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  export default {
    components: {
      VueSlickCarousel
    },
    data() {
      return {
        couponSlick: {
          dots: true,
          infinite: true,
          arrows: false,
          // autoplay: true,
          speed: 400,
          slidesToShow: 3,
          slidesToScroll: 1,

          responsive: [{
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                  centerMode: true,
                centerPadding: '60px',
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
                centerPadding: '0',
                arrows: false

              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                centerMode: false,
                centerPadding: '0',


              }
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                centerMode: false,
                centerPadding: '0',




              }
            }
          ]
        }
      }
    },
    methods: {
      showPrev() {
        this.$refs.carousel.prev()
      },
      showNext() {
        this.$refs.carousel.next()
      },
    },

  }

