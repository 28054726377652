
  export default {
    components: {},
    props: {
      premiumCouponLink: {
        type: String,
        default: "#"
      },
      premiumCouponName: {
        type: String,
        default: 'Place Name'
      },
      premiumCouponDesc: {
        type: String,
        default: 'Place Name'
      },
      premiumCouponLogo: {
        type: String,
        default: 'Place Name'
      },
      premiumCouponCover: {
        type: String,
        default: 'Place Name'
      },
      premiumCouponLocation: {
        type: String,
        default: ''
      },
      bgColor: {
        type: String,
        default: 'bgColor1'
      },
    },
    methods: {
      clickPlaces(slug) {
        this.$router.push({
          path: "/offers/" + slug,
        });
      }
    }
  }
