
  import moment from "moment";
  export default {
    props: {
      activityId: {
        type: String,
        default: "0"
      },
      offerPercentage: {
        type: Number,
        default: 0
      },
      activityName: {
        type: String,
        default: "0"
      },
      activityPlace: {
        type: String,
        default: "0"
      },
      activityKm: {
        type: String,
        default: "0"
      },
      activityPrice: {
        type: Number,
        default: 0
      },
      activityDiscountPrice: {
        type: Number,
        default: 0
      },
      activityLikes: {
        type: Number,
        default: 0
      },
      activityVisits: {
        type: Number,
        default: 0
      },
      newTab: {
        type: Boolean,
        default: false
      },
      offer: {
        type: Boolean,
        default: false
      },
      pickUp: {
        type: Boolean,
        default: false
      },
      isPlace: {
        type: Boolean,
        default: false
      },
      isBlog: {
        type: Boolean,
        default: false
      },
      activityLink: {
        type: String,
        default: "#"
      },
      activityRating: {
        type: Number,
        default: 0
      },
      hideHeart: {
        type: Boolean,
        default: false
      },
      activityImage: {
        type: String
      },
      activityVenue: {
        type: String
      },
      activityCity: {
        type: String
      },
      activityDuration: {
        type: String
      },
      blogDate: {
        type: String
      },
      link: {
        type: String,
        default: "#"
      },
    },
    methods: {
      // To add a fallback placeholder image if not rendereds from API
      setFallbackImageUrl(event) {
        event.target.src = require(`@/assets/img/placeholders/thumbnail-square.svg`)
      },
      // To format the date using moment js
      getDate(blogDateSent) {
        return moment(blogDateSent).format('ll')
      }
    }
  }

